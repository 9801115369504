import { DateRange } from '@mui/lab/DateRangePicker';
import { createTheme } from '@mui/material/styles';

class Calendar {

  private static props: any;
  private static textColor: string;
  private static borderColor: string;
  private static buttonColor: string;
  private static value: DateRange<Date> | null;

  constructor ( props ) {
    Calendar.setProps( props );
    Calendar.setValue( null );
  }

  static setProps ( props ) {
    this.props = props;
  }

  static setValue ( date: DateRange<Date> ) {
    this.value = date;
  }
  static get getValue () {
    return this.value;
  }

  static setTextColor ( textColor ) {
    this.textColor = textColor;
  }

  static setBorderColor ( borderColor ) {
    this.borderColor = borderColor;
  }

  static setButtonColor ( buttonColor ) {
    this.buttonColor = buttonColor;
  }

  static get getTextColor () {
    return this.textColor;
  }

  static get getBorderColor () {
    return this.borderColor;
  }

  static get getButtonColor () {
    return this.buttonColor;
  }

  static get getCalendarMaterialTheme () {
    //----------------CALENDAR INIT STYLES------------------
    return createTheme({
      components: {
        MuiFormLabel: {
          styleOverrides: {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            root: {
              position: 'absolute !important',
              marginLeft: '-14px !important',
              marginTop: '-16px !important',
              fontSize: '14px !important',
              opacity: '.7 !important',
              color: this.props.hasOwnProperty( 'fullWidthBar' ) ? this.props.searchbarThemeColor != null ? !this.props.searchbarThemeColor ? '#fff!important' : '#000!important' : this.props.designMode ? '#fff!important' : '#000!important' : '#000!important',
            }
          }
        },
        MuiInputLabel: {
          styleOverrides: {
            shrink: {
              display: 'none!important'
            }
          }
        },
        MuiFormControl: {
          styleOverrides: {
            root: {
              width: '-webkit-fill-available'
            }
          }
        },
        MuiOutlinedInput: {
          styleOverrides: {
            input: {
              padding: '1px 0',
              fontSize: '14px',
              color: this.props.hasOwnProperty( 'fullWidthBar' ) ? this.props.searchbarThemeColor != null ? !this.props.searchbarThemeColor ? '#fff' : '#000' : this.props.designMode ? '#fff' : '#000' : '#000'
            },
            notchedOutline: {
              display: 'none!important'
            }
          }
        },
        MuiPaper: {
          styleOverrides: {
            root: {
              margin: '15px 0 35px 0',
              borderRadius: this.props !=undefined && this.props.searchbarRadius != null ? this.props.searchbarRadius + 'px' : '10px'
            }
          }
        }
      }
    });
  }
}

export default Calendar;
