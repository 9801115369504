import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ThemeProvider } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns';
import { DesktopDateRangePicker } from '@mui/x-date-pickers-pro/DesktopDateRangePicker';
import { MobileDateRangePicker } from '@mui/x-date-pickers-pro/MobileDateRangePicker';

import { setDateRange } from '../../../../../redux/actions';
import {DateRange} from "@mui/lab/DateRangePicker";

interface CalendarCheckoutInterface {
    dateRange: DateRange<Date>
    setDateRange: (val: DateRange<Date>) => void
    customTheme: any
    focusToggle: any
    disableAllDatesAfterTwoYears: any
}

export default function CalendarCheckout ( props : CalendarCheckoutInterface ) {

    const theme = props.hasOwnProperty('customTheme') ? props.customTheme : '';

    return (
        <>
            <div className="hidden-xs hidden-sm">
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <ThemeProvider theme={theme}>
                        <DesktopDateRangePicker
                            endText="Add dates"
                            calendars={1}
                            value={props.dateRange}
                            minDate={new Date()}
                            inputFormat="dd/MM/yyyy"
                            // toolbarFormat="yyyy-mm-dd"
                            showDaysOutsideCurrentMonth={true}
                            // onChange={( newValue ) => {
                            //   props.setValue( newValue );
                            //   props.focusToggle( 'close_checkout' );
                            //   dispatch( setDateRange( newValue ) )
                            // }}
                            onChange={props.setDateRange}
                            onClose={() => {
                                props.focusToggle('close_all');
                            }}
                            shouldDisableDate={props.disableAllDatesAfterTwoYears}
                            renderInput={(startProps, endProps) => (
                                <React.Fragment>
                                    <TextField {...endProps} autoComplete={'off'}/>
                                </React.Fragment>
                            )}
                        />
                    </ThemeProvider>
                </LocalizationProvider>
            </div>
            <div className="visible-xs visible-sm visible-md">
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <ThemeProvider theme={theme}>
                        <MobileDateRangePicker
                            endText="Add dates"
                            // clearable={true}
                            // clearText={'Clear'}
                            showToolbar={false}
                            value={props.dateRange}
                            minDate={new Date()}
                            inputFormat="dd/MM/yyyy"
                            // disableCloseOnSelect={true}
                            showDaysOutsideCurrentMonth={true}
                            // onChange={( newValue ) => {
                            //   props.setValue( newValue );
                            //   props.focusToggle( 'close_checkout' );
                            //   dispatch( setDateRange( newValue ) )
                            // }}
                            onChange={props.setDateRange}
                            onClose={() => {
                                props.focusToggle('close_all');
                            }}
                            shouldDisableDate={props.disableAllDatesAfterTwoYears}
                            renderInput={(startProps, endProps) => (
                                <React.Fragment>
                                    <TextField {...endProps} autoComplete={'off'}/>
                                </React.Fragment>
                            )}
                        />
                    </ThemeProvider>
                </LocalizationProvider>
            </div>
        </>
    )
}