import React, { useState } from 'react';
import { createTheme } from '@mui/material/styles';

class Popover {
  private static props: any;
  private static open: boolean;
  private static popoverAnchorEl: HTMLButtonElement | null;
  private static popoverOpen: HTMLButtonElement | null;

  constructor ( props ) {
    Popover.setProps( props );
    Popover.setOpen( false );
    Popover.setPopoverAnchorEl( null );
    Popover.setPopoverOpen( Boolean( Popover.getPopoverAnchorEl ) );
  }

  static setProps ( props ) {
    this.props = props;
  }

  static setOpen ( open ) {
    this.open = open;
  }
  static get getOpen () {
    return this.open;
  }

  static setPopoverAnchorEl ( popoverAnchorEl ) {
    this.popoverAnchorEl = popoverAnchorEl;
  }
  static get getPopoverAnchorEl () {
    return this.popoverAnchorEl;
  }

  static setPopoverOpen ( popoverOpen ) {
    this.popoverOpen = popoverOpen;
  }
  static get getPopoverOpen () {
    return this.popoverOpen;
  }

  static get getPopoverId () {
    return this.getPopoverOpen ? 'simple-popover' : undefined;
  }

  static popoverHandleOpen = ( event: React.MouseEvent<HTMLButtonElement> ) => {
    Popover.setPopoverAnchorEl( event.currentTarget );
    document.getElementById( 'guests_label' ).classList.add( 'fade' );
    document.getElementById( 'guests_label' ).parentElement.classList.add( 'focused' );
  };

  static popoverHandleClose = () => {
    document.getElementById( 'guests_label' ).classList.remove( 'fade' );
    document.getElementById( 'guests_label' ).parentElement.classList.remove( 'focused' );
    Popover.setPopoverAnchorEl( null );
  };

  static test () {

    const popover_id = this.getPopoverOpen ? 'simple-popover' : undefined;

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ adults_amount, adults_setAmount ] = useState( 0 );
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ kids_amount, kids_setAmount ] = useState( 0 );
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ infants_amount, infants_setAmount ] = useState( 0 );

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ adults_decreaseHidden, adults_setDecreaseHidden ] = useState( true );
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ kids_decreaseHidden, kids_setDecreaseHidden ] = useState( true );
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ infants_decreaseHidden, infants_setDecreaseHidden ] = useState( true );
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ adults_increaseHidden, adults_setIncreaseHidden ] = useState( false );
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ kids_increaseHidden, kids_setIncreaseHidden ] = useState( false );
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ infants_increaseHidden, infants_setIncreaseHidden ] = useState( false );

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ guests_text, guests_setText ] = useState( 'Who' );
    const guests_sum = null;
  }

  static get getPopoverMaterialTheme () {
    return createTheme({
      components: {
        MuiPaper: {
          styleOverrides: {
            root: {
              margin: '15px 0 35px 0',
              borderRadius: this.props !=undefined && this.props.searchbarRadius != null ? this.props.searchbarRadius + 'px' : '10px'
            }
          }
        }
      }
    });
  }
}

export default Popover;
