import {AdapterDayjs} from "@mui/x-date-pickers-pro/AdapterDayjs";
import {ThemeProvider} from "@mui/material/styles";
import {DateRangePicker, LocalizationProvider, StaticDateRangePicker} from "@mui/x-date-pickers-pro";
import Checkers from "../../../../../helpers/class/Checkers";
import dayjs, {Dayjs} from "dayjs";
import {
    fetchPropertiesAction,
    fetchPropertyPrice,
    setDateRange,
    setOffset, setPageNumber,
    updatePropertyInitialAvailability
} from "../../../../../redux/actions";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalendar as farCalendar} from "@fortawesome/pro-regular-svg-icons";
import React, {useEffect, useState} from "react";
import TextField from "@mui/material/TextField";
import CustomDateRangePickerDay from "../../content/columned/right/CustomDateRangePickerDay";
import {DateRange} from "@mui/x-date-pickers-pro/DateRangePicker";
import {useDispatch, useSelector} from "react-redux";
import cloneDeep from 'lodash/cloneDeep'
import {Button} from "@mui/material";
import {ClickAwayListener} from "@mui/material";
import LgConsts from "../../../../../helpers/LgConsts";
import lgConsts from "../../../../../helpers/LgConsts";

export default function DateRangeWrapper (props : { forList, theme, isStatic, containerClass?: string, toggleDrawer ?: (val) => void } ) {

    const containerClass = props.hasOwnProperty('containerClass') ? props.containerClass : 'pe-1'

    const dispatch = useDispatch()

    const checkin = useSelector(state => state['propertiesParams']['checkin'])
    const checkout = useSelector(state => state['propertiesParams']['checkout'])

    const propertyAvailability = useSelector(state => state['propertyAvailability'])

    const isOpenCalendar = useSelector(state => state['appSettings']['isOpenCalendar'])

    const [openState, setOpenState] = useState(false)

    const value: DateRange<Dayjs> = useSelector(state => [
        Checkers.isValidDate(state['propertiesParams']['checkin']) ? dayjs(state['propertiesParams']['checkin']) : null,
        Checkers.isValidDate(state['propertiesParams']['checkout']) ? dayjs(state['propertiesParams']['checkout']) : null
    ])


    const datePickerMode = () => {
        let mode = ""

        if (checkin == "Invalid Date" && checkout == "Invalid Date") {
            mode = LgConsts.datePickerModeForCheckin
        } else if (checkin == null && checkout == null) {
            mode = LgConsts.datePickerModeForCheckin
        } else if (Checkers.isValidDate(checkin) && Checkers.isValidDate(checkout)) {
            mode = LgConsts.datePickerModeResetRange
        } else if (Checkers.isValidDate(checkin) && !Checkers.isValidDate(checkout)) {
            mode = LgConsts.datePickerModeForCheckout
        }

        return mode;
    }

    const twoYearsAvailabilty = propertyAvailability.checkin != null && propertyAvailability.checkin.result != undefined ? propertyAvailability.checkin.result[0].availability.availability : null
    const twoYearsAvailabiltyCheckout = propertyAvailability.checkout != null && propertyAvailability.checkout.result != undefined ? propertyAvailability.checkout.result[0].availability.availability : null
    const minStayToCheck = twoYearsAvailabilty != null && Checkers.isValidDate(checkin) && twoYearsAvailabilty[checkin] != undefined ? twoYearsAvailabilty[checkin]['min_stay'] : null

    const bookingMaxInAdvance = propertyAvailability.checkin != null && propertyAvailability.checkin.result != undefined ? propertyAvailability.checkin.bookingMaxInAdvance : null

    const [dateRangeForCheckout, setDateRangeForCheckout] = useState(null)
    const dateRangeForCheckoutHandler = () => {
        if (props.forList) {
            // let diffFromToday = Math.abs(checkin.diff(checkout.format('YYYY-MM-DD'), 'day'))
            let checkinObject = dayjs(checkin)
            let checkoutObject = dayjs(checkout)

            if (checkinObject>checkoutObject) {
                dispatch(setDateRange([checkoutObject, null]))
            }
            return false
        }

        if (datePickerMode() == LgConsts.datePickerModeForCheckout) {
            let newDateRange = {};

            let checkinDateObject = new Date(checkin)

            let availabilityFlag = 0
            let counter = 0
            Object.keys(twoYearsAvailabiltyCheckout).forEach((key, index, item) => {
                let theDateToCheck = new Date(twoYearsAvailabiltyCheckout[key]['date'])
                let theDateToCheckAvailability = twoYearsAvailabiltyCheckout[key]['status']

                if (theDateToCheck > checkinDateObject) {
                    counter++
                    if (counter == minStayToCheck && availabilityFlag == 0) {
                        availabilityFlag = 1
                    }
                    if (availabilityFlag == 1) {
                        if (theDateToCheckAvailability != LgConsts.AVAILABILITY_59_AVAILABLE_FOR_ARRIVAL) {
                            availabilityFlag = 2
                        }
                        newDateRange[key] = twoYearsAvailabiltyCheckout[key]
                    }
                }
            })

            return newDateRange
        }
    }

    const isAvailableForSelectedRange = () => {
        if (datePickerMode() == LgConsts.datePickerModeForCheckout) {
            let newDateRange = {};

            let checkinDateObject = new Date(checkin)

            let availabilityFlag = 0
            let counter = 0
            Object.keys(twoYearsAvailabiltyCheckout).forEach((key, index, item) => {
                let theDateToCheck = new Date(twoYearsAvailabiltyCheckout[key]['date'])
                let theDateToCheckAvailability = twoYearsAvailabiltyCheckout[key]['status']

                if (theDateToCheck > checkinDateObject) {
                    counter++
                    if (counter == minStayToCheck && availabilityFlag == 0) {
                        availabilityFlag = 1
                    }
                    if (availabilityFlag == 1) {
                        if (theDateToCheckAvailability != LgConsts.AVAILABILITY_59_AVAILABLE_FOR_ARRIVAL) {
                            availabilityFlag = 2
                        }
                        newDateRange[key] = twoYearsAvailabiltyCheckout[key]
                    }
                }
            })

            return newDateRange
        }
    }

    const disabledDaysHandler = (dateParam) => {
        if (props.forList) {
            return false
        }

        if (isOpenCalendar == 1) {
            return false
        }

        let today = dayjs()
        let diffFromToday = Math.abs(dateParam.diff(today.format('YYYY-MM-DD'), 'day'))

        if (diffFromToday<bookingMaxInAdvance) {
            return true
        }



        let theDateToCheck = dayjs(dateParam).format('YYYY-MM-DD') //dateParam.toISOString().slice(0, 10)
        // .toISOString().split('T')[0];

        let currentAvailability = twoYearsAvailabilty
        if (datePickerMode() == LgConsts.datePickerModeForCheckout) {
            currentAvailability = dateRangeForCheckout
        }

        if (datePickerMode() == LgConsts.datePickerModeForCheckin && currentAvailability[theDateToCheck] != undefined && (currentAvailability[theDateToCheck]['checkinAllowed'] == false || currentAvailability[theDateToCheck]['checkinOrCheckoutRequired'] == false)){
            return true
        }

        if (datePickerMode() == LgConsts.datePickerModeForCheckout && currentAvailability!=undefined && currentAvailability[theDateToCheck] != undefined && (currentAvailability[theDateToCheck]['checkoutAllowed'] == false || currentAvailability[theDateToCheck]['checkinOrCheckoutRequired'] == false)){
            return true
        }

        if (theDateToCheck == checkin) {
            return true
        }

        if (currentAvailability != null && currentAvailability[theDateToCheck] != undefined && currentAvailability[theDateToCheck]['status'] == LgConsts.AVAILABILITY_59_AVAILABLE_FOR_ARRIVAL) {
            return false;
        } else {
            return true;
        }
    }

    useEffect(() => {
        setDateRangeForCheckout(dateRangeForCheckoutHandler())
    }, [checkin, checkout])

    const datePickerClassHandler = (dateParam) => {
        if (props.forList) {
            return false
        }

        let className = "";
        let mode = datePickerMode()

        let theDateToCheck = dayjs(dateParam).format('YYYY-MM-DD') //dateParam.toISOString().slice(0, 10)

        let checkinDateObject = new Date(checkin)
        let checkoutDateObject = new Date(checkout)
        let theDateToCheckObject = new Date(theDateToCheck)

        if (mode == LgConsts.datePickerModeForCheckin) {
            if (twoYearsAvailabilty[theDateToCheck] == undefined) {
                return className;
            }

            if (twoYearsAvailabiltyCheckout[theDateToCheck] == undefined) {
                return className;
            }

            if (twoYearsAvailabilty[theDateToCheck]['status'] != lgConsts.AVAILABILITY_59_AVAILABLE_FOR_ARRIVAL && twoYearsAvailabiltyCheckout[theDateToCheck]['status'] == lgConsts.AVAILABILITY_59_AVAILABLE_FOR_ARRIVAL) {
                className = "date-picker-item-inactive";

                return className
            } else if (twoYearsAvailabilty[theDateToCheck]['status'] != LgConsts.AVAILABILITY_59_AVAILABLE_FOR_ARRIVAL) {
                className = "date-picker-item-line-through";

                return className
            }
        } else if (mode == LgConsts.datePickerModeForCheckout) {
            if (theDateToCheckObject >= checkinDateObject && twoYearsAvailabiltyCheckout[theDateToCheck]['status'] == LgConsts.AVAILABILITY_59_AVAILABLE_FOR_ARRIVAL) {

                return className
            } else {
                className = "date-picker-item-line-through";

                return className
            }
        } else if (mode == LgConsts.datePickerModeResetRange) {
            if (twoYearsAvailabilty[theDateToCheck] == undefined) {
                return className;
            }

            if (twoYearsAvailabiltyCheckout[theDateToCheck] == undefined) {
                return className;
            }

            if (twoYearsAvailabilty[theDateToCheck]['status'] != lgConsts.AVAILABILITY_59_AVAILABLE_FOR_ARRIVAL && twoYearsAvailabiltyCheckout[theDateToCheck]['status'] == lgConsts.AVAILABILITY_59_AVAILABLE_FOR_ARRIVAL) {
                className = "date-picker-item-inactive";

                return className
            } else if (twoYearsAvailabilty[theDateToCheck]['status'] != LgConsts.AVAILABILITY_59_AVAILABLE_FOR_ARRIVAL) {
                className = "date-picker-item-line-through";

                return className
            }

        }

        return className
    }

    const toolTipHandler = (dateParam) => {
        if (props.forList) {
            return false
        }

        let mode = datePickerMode()
        let tooltipCheckin = ''
        let tooltipCheckout = ''

        // if(isMobile){ return ''}

        let theDateToCheck = dayjs(dateParam).format('YYYY-MM-DD') //dateParam.toISOString().slice(0, 10)

        let checkinDateObject = new Date(checkin)
        let checkoutDateObject = new Date(checkout)
        let theDateToCheckObject = new Date(theDateToCheck)

        if (twoYearsAvailabilty[theDateToCheck] != undefined) {
            tooltipCheckin = twoYearsAvailabilty[theDateToCheck].status_desc
        }

        if (twoYearsAvailabiltyCheckout[theDateToCheck] != undefined) {
            // tooltipCheckout = twoYearsAvailabiltyCheckout[theDateToCheck].status_desc + " / minimum stay:" + twoYearsAvailabilty[theDateToCheck]['min_stay']
            tooltipCheckout = "minimum stay: " + twoYearsAvailabilty[theDateToCheck]['min_stay']
        }



        if (mode == LgConsts.datePickerModeForCheckin && twoYearsAvailabiltyCheckout[theDateToCheck] != undefined && twoYearsAvailabilty[theDateToCheck]['checkinAllowed'] == false) {
            tooltipCheckin = "Not available for check-in"
        }

        if (mode == LgConsts.datePickerModeForCheckout && twoYearsAvailabiltyCheckout[theDateToCheck] != undefined && twoYearsAvailabilty[theDateToCheck]['checkoutAllowed'] == false) {
            tooltipCheckin = "Not available for check-out"
        }

        if (mode == LgConsts.datePickerModeForCheckin) {
            if (twoYearsAvailabilty[theDateToCheck] != undefined && twoYearsAvailabilty[theDateToCheck]['status'] != LgConsts.AVAILABILITY_59_AVAILABLE_FOR_ARRIVAL && twoYearsAvailabiltyCheckout[theDateToCheck]['status'] == LgConsts.AVAILABILITY_59_AVAILABLE_FOR_ARRIVAL && twoYearsAvailabiltyCheckout[theDateToCheck]['temp_status'] != LgConsts.AVAILABILITY_59_AVAILABLE_FOR_ARRIVAL) {
                return twoYearsAvailabiltyCheckout[theDateToCheck]['status_desc']
            }

            return tooltipCheckin //+ " - " + tooltipCheckin
        }
        if (mode == LgConsts.datePickerModeForCheckout) {
            if (disabledDaysHandler(dateParam) && theDateToCheck != checkin) {
                tooltipCheckout = "minimum stay violation"
            }
            if (twoYearsAvailabilty[theDateToCheck] != undefined && twoYearsAvailabilty[theDateToCheck]['status'] != LgConsts.AVAILABILITY_59_AVAILABLE_FOR_ARRIVAL && twoYearsAvailabilty[theDateToCheck]['temp_status'] == LgConsts.AVAILABILITY_59_AVAILABLE_FOR_ARRIVAL) {
                tooltipCheckout = "minimum stay: " + twoYearsAvailabilty[checkin]['min_stay']
            }
            return tooltipCheckout
        }
        if (theDateToCheckObject >= checkinDateObject && theDateToCheckObject <= checkoutDateObject) {
            if (mode == LgConsts.datePickerModeResetRange) {
                if (disabledDaysHandler(dateParam) && theDateToCheck != checkin) {
                    tooltipCheckout = "minimum stay violation"
                }
                if (twoYearsAvailabilty[theDateToCheck] != undefined && twoYearsAvailabilty[theDateToCheck]['status'] != LgConsts.AVAILABILITY_59_AVAILABLE_FOR_ARRIVAL && twoYearsAvailabilty[theDateToCheck]['temp_status'] == LgConsts.AVAILABILITY_59_AVAILABLE_FOR_ARRIVAL) {
                    tooltipCheckout = "minimum stay: " + twoYearsAvailabilty[checkin]['min_stay']
                }
                if (twoYearsAvailabilty[theDateToCheck] != undefined && twoYearsAvailabilty[theDateToCheck]['status'] != LgConsts.AVAILABILITY_59_AVAILABLE_FOR_ARRIVAL && twoYearsAvailabiltyCheckout[theDateToCheck]['status'] == LgConsts.AVAILABILITY_59_AVAILABLE_FOR_ARRIVAL && twoYearsAvailabiltyCheckout[theDateToCheck]['temp_status'] != LgConsts.AVAILABILITY_59_AVAILABLE_FOR_ARRIVAL) {
                    return twoYearsAvailabiltyCheckout[theDateToCheck]['status_desc']
                }
                return tooltipCheckout
            }
            return ''
        } else {
            return tooltipCheckin
        }

        return null
    }

    const ModalActionBar = ({
                                onAccept,
                                onCancel,
                                onClear,
                                onSetToday
                            }) => {

        return (
            <div className={"d-flex flex-row-reverse p-b-20 m-l-15 m-r-15"}>
                <div className={"col-auto"}>
                    <Button className={"btn-design-color btn btn-primary text-lowercase"}
                            onClick={() => setOpenState(false)}> close </Button>
                </div>
                <div className={"col-auto"}>
                    <Button className={' text-lowercase'}
                            onClick={() => dispatch(setDateRange([null, null]))}> clear </Button>
                </div>
            </div>
        );
    };

    const SwipperActionBar = ({
                                  onAccept,
                                  onCancel,
                                  onClear,
                                  onSetToday
                              }) => {

        return (
            <>
                {/*<div className={"d-flex flex-row-reverse p-b-20 m-l-15 m-r-15"}>*/}
                {/*    <div className={"col-auto"}>*/}
                {/*        <Button className={"text-lowercase"} onClick={() => props.toggleDrawer(false)}> close </Button>*/}
                {/*    </div>*/}
                {/*    <div className={"col-auto"}>*/}
                {/*        <Button className={' text-lowercase'}*/}
                {/*                onClick={() => dispatch(setDateRange([null, null]))}> clear </Button>*/}
                {/*    </div>*/}
                {/*</div>*/}
            </>
        );
    };

    // const uiSearchBarDivHeight = useSelector(state => state['uiData']['searchBarDivHeight'])
    //
    // const propertiesParams = useSelector(state => state['propertiesParams']);

    const onChangeHandler = (newValue: DateRange<dayjs.Dayjs>) => {

        let startDate = cloneDeep(newValue[0])
        let endDate = cloneDeep(newValue[1])

        if (startDate == null && endDate != null) {
            startDate = cloneDeep(endDate)
            endDate = null
        }

        if (Checkers.isValidDate(checkin) && Checkers.isValidDate(checkout)) {
            // console.log("forDebug", "case 3")
            endDate = null
        }

        if (Checkers.isValidDate(checkin) && !Checkers.isValidDate(checkout) && startDate != null && endDate == null) {
            endDate = cloneDeep(startDate)
            startDate = dayjs(checkin)
        }

        dispatch(setDateRange([startDate, endDate]))

        // if (props.forList && startDate != null && endDate != null) {
        //     let parameters = cloneDeep(propertiesParams);
        //     parameters.checkin = startDate.format("YYYY-MM-DD")
        //     parameters.checkout = endDate.format("YYYY-MM-DD")
        //     dispatch(setOffset(0))
        //     dispatch(setPageNumber(1))
        //     dispatch<any>(fetchPropertiesAction(parameters, 0, uiSearchBarDivHeight))
        //     props.toggleDrawer(false)
        // }
    }

    return (
        <>
            <ClickAwayListener onClickAway={() => setOpenState(false)}>
                <div className={"col-12 text-left " + containerClass}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <ThemeProvider theme={props.theme}>
                            {
                                props.isStatic == 0 ?
                                    <DateRangePicker
                                        value={value}
                                        autoFocus={true}
                                        inputFormat="DD/MM/YYYY"
                                        showToolbar={false}
                                        disablePast={true}
                                        onChange={(newValue) => onChangeHandler(newValue)}
                                        components={{
                                            ActionBar: ModalActionBar
                                        }}
                                        open={openState}
                                        onAccept={(newDate) => {
                                            if (newDate[0] != null && newDate[1] != null) {
                                                setOpenState(false)
                                            }
                                        }}
                                        startText={"Add date"}
                                        endText={"Add date"}
                                        PopperProps={{
                                            placement: "bottom-end"
                                        }}
                                        shouldDisableDate={(dateParam) => disabledDaysHandler(dateParam)}
                                        renderInput={(startProps, endProps) => (
                                            <>
                                                <div className="col-6 pe-1 text-left"
                                                     onClick={() => setOpenState(true)}>
                                                    <div
                                                        id="form-group-default-checkin"
                                                        className="form-group form-group-default form-group-default-checkin">
                                                        <label id="checkin_label" className="form-label bold text-left">
                                                            <FontAwesomeIcon className="hidden-md"
                                                                             icon={farCalendar}/>&nbsp;Check
                                                            in
                                                        </label>
                                                        <React.Fragment>
                                                            <TextField {...startProps} autoComplete={'off'}/>
                                                        </React.Fragment>
                                                    </div>
                                                </div>
                                                <div className="col-6 ps-1 text-left"
                                                     onClick={() => setOpenState(true)}>
                                                    <div id="form-group-default-checkout"
                                                         className="form-group form-group-default form-group-default-checkout">
                                                        <label id="checkout_label"
                                                               className="form-label bold text-left">
                                                            <FontAwesomeIcon className="hidden-md"
                                                                             icon={farCalendar}/>&nbsp;Check
                                                            out
                                                        </label>
                                                        <React.Fragment>
                                                            <TextField {...endProps} autoComplete={'off'}/>
                                                        </React.Fragment>
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                        renderDay={(day, pickersDayProps) => {
                                            return (
                                                <CustomDateRangePickerDay pickersDayProps={pickersDayProps}
                                                                          key={pickersDayProps.key}
                                                                          toolTipHandler={toolTipHandler}
                                                                          datePickerClassHandler={datePickerClassHandler}
                                                />
                                            );
                                        }}
                                    />
                                    :
                                    <StaticDateRangePicker
                                        calendars={1}
                                        showToolbar={false}
                                        value={value}
                                        onChange={(newValue) => onChangeHandler(newValue)}
                                        renderInput={(startProps, endProps) => (
                                            <></>
                                        )}
                                        components={{
                                            ActionBar: SwipperActionBar
                                        }}
                                        disablePast={true}
                                        startText={"Add date"}
                                        endText={"Add date"}
                                        shouldDisableDate={(dateParam) => disabledDaysHandler(dateParam)}
                                        renderDay={(day, pickersDayProps) => {
                                            return (
                                                <CustomDateRangePickerDay pickersDayProps={pickersDayProps}
                                                                          key={pickersDayProps.key}
                                                                          toolTipHandler={toolTipHandler}
                                                                          datePickerClassHandler={datePickerClassHandler}
                                                />
                                            );
                                        }}
                                    />
                            }
                        </ThemeProvider>
                    </LocalizationProvider>
                </div>
            </ClickAwayListener>
        </>
    )
}