export const customAsyncSelectStyles = {
    container: ( provided ) => ({ ...provided,
        paddingTop: '20px', maxWidth: 350 }),
    control: ( provided ) => ({ ...provided,
        minHeight: '32px',
        border: 'none',
        backgroundColor: 'transparent!important',
        borderColor: 'transparent!important',
        boxShadow: 'none!important',
        fontSize: '14px' }),
    valueContainer: ( provided ) => ({ ...provided,
        padding: '0 8px' }),
    menuList: ( provided, state ) => ({ ...provided,
        backgroundColor: state.isFocused ? '#f4f4f4' : state.isSelected ? '#f4f4f4' : state.isActive ? '#f4f4f4' : 'transparent',
        background: state.isFocused ? '#f4f4f4' : state.isSelected ? '#f4f4f4' : state.isActive ? '#f4f4f4' : 'transparent',
        margin: '8px 10px',
        letterSpacing: '0.01em',
        fontSize: '14px',
        fontFamily: '"Inter UI", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif'
    }),
    option: ( provided, state ) => ({ ...provided,
        backgroundColor: state.isFocused ? '#f4f4f4' : state.isSelected ? '#f4f4f4' : state.isActive ? '#f4f4f4' : 'transparent',
        background: state.isFocused ? '#f4f4f4' : state.isSelected ? '#f4f4f4' : state.isActive ? '#f4f4f4' : 'transparent',
        color: '#4b4b4b',
        padding: '6px 13px',
        letterSpacing: '0.01em',
        fontFamily: '"Inter UI", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif'
    }),
    groupHeading: ( provided ) => ({ ...provided,
        padding: '6px',
        fontWeight: '600',
        fontSize: '11px',
        color: '#000',
        letterSpacing: '0.01em',
        fontFamily: '"Inter UI", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif'
    }),
    input: ( provided ) => ({ ...provided,
        margin: 0,
        padding: 0 }),
    placeholder: ( provided ) => ({ ...provided,
        opacity: '0.7' }),
};