import { createTheme } from '@mui/material/styles';

class Dialog {
  private static props: any;

  constructor ( props ) {
    Dialog.setProps( props );
  }

  static setProps ( props ) {
    this.props = props;
  }

  static get getDialogMaterialTheme () {
    return createTheme({
      components: {
        MuiPaper: {
          styleOverrides: {
            root: {
              borderRadius: this.props !=undefined && this.props.searchbarRadius != null ? this.props.searchbarRadius + 'px' : '10px'
            }
          }
        }
      }
    });
  }
}

export default Dialog;
