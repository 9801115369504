import {DateRangePickerDay} from "@mui/x-date-pickers-pro";
import {Tooltip} from "@mui/material";
import React from "react";
import {useSelector} from "react-redux";
import {ClickAwayListener} from "@mui/material";

export default function CustomDateRangePickerDay ({pickersDayProps, toolTipHandler, datePickerClassHandler} ) {

    const [tooltipIsOpen, setTooltipIsOpen] = React.useState(false);

    const isMobile = useSelector(state => state['appSettings']['isMobile'])

    return (
        <ClickAwayListener onClickAway={() => setTooltipIsOpen(false)}>
            <Tooltip placement={"top"} title={toolTipHandler(pickersDayProps.day)}
                     open={tooltipIsOpen}
                     onClick={() => setTooltipIsOpen(true)}
                     componentsProps={{
                         tooltip: {
                             sx: {
                                 backgroundColor: '#fff',
                                 color: "#000",
                                 boxShadow: "0px 0px 2px #000"
                             },
                         },
                     }}
            >
                <span className={"DateRangePickerDay-Wrapper " + datePickerClassHandler(pickersDayProps.day)}
                      style={{
                          borderTopLeftRadius: (pickersDayProps.isStartOfHighlighting ?"50%":""),
                          borderBottomLeftRadius: (pickersDayProps.isStartOfHighlighting ?"50%":""),
                          borderTopRightRadius: (pickersDayProps.isEndOfHighlighting ?"50%":""),
                          borderBottomRightRadius: (pickersDayProps.isEndOfHighlighting ?"50%":""),
                          background: (pickersDayProps.isHighlighting ? "rgba(93,137,179, 0.5)":"") }} >
                    <DateRangePickerDay { ...pickersDayProps } />
                </span>
            </Tooltip>
        </ClickAwayListener>
    )
}