
class LgConsts {
    static AVAILABILITY_59_AVAILABLE_FOR_ARRIVAL = "59";
    static AVAILABILITY_60_UNAVAILABLE = 60;
    static AVAILABILITY_61_BOOKED = 61;
    static AVAILABILITY_62_CLOSED = 62;
    static AVAILABILITY_63_STOP_SALES = 63;
    static AVAILABILITY_185_NOT_AVAILABLE_FOR_ARRIVAL = 185;
    static datePickerModeForCheckin = "forCheckIn"
    static datePickerModeForCheckout = "forCheckOut"
    static datePickerModeResetRange = "resetRange"
    static BOOKING_TYPE_1_INQUIRIES = "1"
    static BOOKING_TYPE_2_WEBHOTELIER = "2"

    static PROPERTY_TYPE_2_HOTEL = 2

}

export default LgConsts